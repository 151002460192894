<template>
  <main class="app__page process">
    <div class="process__wrapper">
      <div class="process__container">
        <h1 class="process__title title title--bb">
          {{ $t("pages.process.title") }}
        </h1>

        <div v-if="pageLocale" class="process__content-wrapper">
          <div class="process__content">
            <p class="process__main-text is-observable">
              <span class="d-block observe-target observe-80-bottom">
                {{ pageLocale.main_text[0] }}
                <span class="process__word-gold">{{
                  pageLocale.main_text[1]
                }}</span>
                {{ pageLocale.main_text[2] }}
              </span>
            </p>
            <div class="process__inner">
              <div class="process__texts">
                <p class="process__text is-observable">
                  <span class="d-block observe-target observe-40-right">
                    {{ pageLocale.info[0] }}
                  </span>
                </p>
                <p class="process__text process__text--pr100 is-observable">
                  <span class="d-block observe-target observe-40-left">
                    {{ pageLocale.info[1] }}
                  </span>
                </p>
                <p class="process__text is-observable">
                  <span class="d-block observe-target observe-40-left">
                    {{ pageLocale.info[2] }}
                  </span>
                </p>
                <p class="process__text process__text--big is-observable">
                  <span class="d-block observe-target observe-40-left">
                    {{ pageLocale.info[3] }}
                  </span>
                </p>
              </div>

              <div class="process__block-count">
                <svg
                  width="300"
                  height="300"
                  viewBox="0 0 300 300"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  class="process__count-line"
                >
                  <path
                    class="process__count-line-path"
                    d="M150.5 2.99999C150.503 2.99999 150.506 2.99999 150.509 2.99999C189.252 3.00249 226.417 18.4038 253.872 45.8358C281.329 73.2703 296.837 110.503 296.999 149.385C297.161 188.268 281.964 225.629 254.736 253.293C227.509 280.957 190.469 296.67 151.725 296.995C112.98 297.32 75.684 282.231 47.9996 255.028C20.315 227.825 4.49756 190.723 4.01153 151.844C3.52551 112.964 18.4105 75.4764 45.4065 47.5849C72.4003 19.6958 109.304 3.673 148.042 3.02068C148.045 3.02062 148.047 3.02059 148.05 3.02053L150.5 2.99999Z"
                    stroke="#CFB16E"
                    stroke-width="4"
                    stroke-dasharray="922.0608520507812"
                    :style="{
                      'stroke-dashoffset': percent
                        ? ((100 - percent) / 100) * 922
                        : 922
                    }"
                  />
                </svg>

                <div class="process__count-wrap">
                  <div class="process__count">
                    {{ percent + "%" }}
                    <sup class="process__count-sup">*</sup>
                  </div>

                  <div class="process__count-caption">
                    {{ pageLocale.end }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="process__swiper-wrap">
            <swiper
              :modules="[Navigation, Mousewheel]"
              :slidesPerView="'auto'"
              :slidesPerGroup="1"
              :mousewheel="true"
              :speed="400"
              :navigation="{
                nextEl: '.swiper__button-next',
                prevEl: '.swiper__button-prev'
              }"
              @swiper="setSwiper"
              class="swiper--process"
            >
              <swiper-slide
                class="swiper__process-slide"
                v-for="(slide, index) in slides"
                :key="index"
              >
                <div class="swiper__slide-wrap">
                  <img
                    class="swiper__process-img"
                    :src="slide.img"
                    :alt="index"
                  />
                  <div class="swiper__process-line">
                    {{ slide.year }}
                  </div>
                  <div class="swiper__process-line">
                    {{ slide.period }}
                  </div>
                </div>
              </swiper-slide>
            </swiper>

            <button type="button" class="swiper__button-prev r-btn">
              <svg-icon
                width="14"
                height="10"
                name="arrow_left"
                class="btn-icon"
              />
            </button>
            <button type="button" class="swiper__button-next r-btn">
              <svg-icon
                width="14"
                height="10"
                name="arrow_right"
                class="btn-icon"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
    <PageFooter />
  </main>
</template>
<script>
import { ref } from "vue";
import { pageLocale } from "@/mixins/pageLocale.js";
import { observer } from "@/mixins/observer.js";
import PageFooter from "@/components/PageFooter";

import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
import { Navigation, Mousewheel } from "swiper";

export default {
  name: "process",
  components: { PageFooter, Swiper, SwiperSlide },
  mixins: [pageLocale, observer],
  setup() {
    const processSwiper = ref(null);
    const setSwiper = (swiper) => {
      processSwiper.value = swiper;
    };

    return {
      processSwiper,
      setSwiper,
      Navigation,
      Mousewheel
    };
  },
  data() {
    return {
      slides: null,
      percent: 0,
      loadQty: 3 // смонтировано + перевод + апи
    };
  },
  computed: {
    api() {
      return this.$store.state.api;
    },
    locale() {
      return this.$i18n.locale;
    },
    processData() {
      return this.$store.state.processData;
    }
  },
  methods: {
    getSlds(force) {
      if (this.processData && !force) {
        this.slides = this.processData.slides;

        this.loaded = this.loaded + 1;

        setTimeout(() => {
          this.processSwiper.update();
          this.percent = this.processData.percent;
        }, 500);

        return;
      }

      this.$axios({
        method: "get",
        url: this.api + "/process/" + this.locale,
        timeout: 90000
      })
        .then((response) => {
          if (response && response.data) {
            this.$store.state.processData = response.data;

            this.slides = response.data.slides;

            setTimeout(() => {
              this.processSwiper.update();
              this.percent = response.data.percent;
            }, 500);
          } else {
            this.$toast.error(this.$t("error"));
          }

          this.loaded = this.loaded + 1;
        })
        .catch((error) => {
          this.$toast.error(this.$t("error"));
          if (error && error.response) console.log(error.response);
          this.loaded = this.loaded + 1;
        });
    }
  },
  created() {
    this.getSlds();
  },
  watch: {
    locale() {
      this.getSlds(true);
    }
  }
};
</script>
<style lang="less">
.process {
  .padding-page-top;
  position: relative;
  overflow: hidden;
  background-image: linear-gradient(
      360deg,
      #404853,
      rgba(64, 72, 83, 1) 50%,
      rgba(64, 72, 83, 0)
    ),
    url("~@/assets/images/process-bg.jpg");
  background-repeat: no-repeat;
  background-size: contain;
  @media @bw1440 {
    background-image: linear-gradient(
        360deg,
        #404853,
        rgba(64, 72, 83, 1) 50%,
        rgba(64, 72, 83, 0)
      ),
      url("~@/assets/images/process-bg-1170.jpg");
  }
  @media @bw1020 {
    padding: 150px 0 0;
    background-image: linear-gradient(
        360deg,
        #404853,
        rgba(64, 72, 83, 1) 50%,
        rgba(64, 72, 83, 0)
      ),
      url("~@/assets/images/process-bg-768.jpg");
  }
  @media @bw768 {
    padding: 140px 0 0;
    background-image: linear-gradient(
        360deg,
        #404853,
        rgba(64, 72, 83, 1) 70%,
        rgba(64, 72, 83, 0)
      ),
      url("~@/assets/images/process-bg-414.jpg");
  }
  @media @bw400 {
    padding: 130px 0 0;
    background-image: linear-gradient(
        360deg,
        #404853,
        rgba(64, 72, 83, 1) 70%,
        rgba(64, 72, 83, 0)
      ),
      url("~@/assets/images/process-bg-400.jpg");
  }
  &__container {
    .container;
  }
  &__content {
    margin-bottom: 100px;
    @media @bw768 {
      margin-bottom: 30px;
    }
  }
  &__main-text {
    margin: 0 0 0 210px;
    max-width: 710px;
    width: 100%;
    font-weight: 700;
    font-size: 36px;
    line-height: 1.2;
    color: @white;
    @media @bw1440 {
      margin: 0 0 0 50px;
    }
    @media @bw1020 {
      margin: 0 0 20px 40px;
      max-width: 473px;
      font-size: 24px;
    }
    @media @bw768 {
      margin: 0 15px 40px auto;
      font-size: 20px;
    }
    @media @bw600 {
      max-width: calc(100% - 70px);
    }
    @media @bw400 {
      margin: 0 0 40px auto;
      font-size: 18px;
      max-width: calc(100% - 12px);
    }
  }
  &__word-gold {
    color: @gold;
  }
  &__inner {
    display: flex;
    //align-items: flex-end;
    margin-top: 30px;
    @media @bw1440 {
      margin-top: 0px;
    }
    @media @bw1170 {
      align-items: flex-start;
    }
    @media @bw768 {
      flex-direction: column;
    }
  }
  &__texts {
    margin-left: auto;
    margin-right: 135px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    max-width: 570px;
    width: 100%;
    @media @bw1440 {
      padding-bottom: 30px;
      margin-right: 100px;
    }
    @media @bw1170 {
      margin-right: 45px;
      padding-top: 40px;
      padding-bottom: 0;
      max-width: 375px;
    }
    @media @bw768 {
      margin-left: 0;
      padding-top: 0;
    }
    @media @bw400 {
      margin-bottom: 20px;
    }
  }
  &__text {
    margin: 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.5;
    color: @white_80;
    & + & {
      margin-top: 20px;
    }
    &--pr100 {
      padding-right: 100px;
      @media @bw1170 {
        padding-right: 50px;
      }
      @media @bw768 {
        padding-right: 207px;
      }
      @media @bw400 {
        padding-right: 0;
      }
    }
    &--big {
      margin-top: 40px;
      align-self: flex-end;
      text-align: right;
      font-weight: 700;
      font-size: 20px;
      white-space: pre-wrap;
    }
  }
  &__block-count {
    box-sizing: border-box;
    position: relative;
    width: 300px;
    height: 300px;
    flex-shrink: 0;
    @media @bw1440 {
      width: 280px;
      height: 280px;
    }
    @media @bw1170 {
      width: 207px;
      height: 207px;
    }
    @media @bw768 {
      margin-top: -40px;
      margin-left: 170px;
    }
    @media @bw500 {
      margin-left: auto;
    }
    @media @bw400 {
      margin-top: 0;
    }
  }
  &__count-line {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  &__count-line-path {
    transition: stroke-dashoffset 2s ease-out;
  }
  &__count-wrap {
    position: absolute;
    top: 20px;
    right: 20px;
    bottom: 20px;
    left: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: @white_15;
    @media @bw1170 {
      top: 14px;
      right: 14px;
      bottom: 14px;
      left: 14px;
    }
  }
  &__count {
    font-family: @font2;
    font-weight: 700;
    font-size: 100px;
    line-height: 1;
    color: @white;
    letter-spacing: -0.03em;
    //Letter spacing: -3%
    @media @bw1440 {
      font-size: 90px;
    }
    @media @bw1170 {
      font-size: 65px;
    }
  }
  &__count-sup {
    top: -1.2em;
    font-size: 40px;
    @media @bw1440 {
      top: -1em;
      font-size: 37px;
    }
    @media @bw1170 {
      top: -0.85em;
      font-size: 30px;
    }
  }
  &__count-caption {
    max-width: 210px;
    width: 100%;
    text-align: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.14;
    color: @white;
    @media @bw1170 {
      font-size: 12px;
    }
  }
  &__swiper-wrap {
    position: relative;
    margin-bottom: 150px;
    @media @bw1510 {
      padding-bottom: 86px;
    }
    @media @bw1170 {
      margin-bottom: 100px;
    }
    @media @bw768 {
      margin-bottom: 50px;
    }
    .swiper__button-prev,
    .swiper__button-next {
      position: absolute;
      top: 130px;
      @media @bw1510 {
        top: auto;
        bottom: 0;
      }
      @media @bw1440 {
        bottom: auto;
        top: 130px;
      }
      @media @bw1170 {
        top: auto;
        bottom: 0;
      }
    }
    .swiper__button-prev {
      left: -104px;
      @media @bw1680 {
        left: -70px;
      }
      @media @bw1510 {
        left: 0;
      }
      @media @bw1440 {
        left: -70px;
      }
      @media @bw1170 {
        left: 0;
      }
    }
    .swiper__button-next {
      right: -104px;
      @media @bw1680 {
        right: -70px;
      }
      @media @bw1510 {
        right: 0;
      }
      @media @bw1440 {
        right: -70px;
      }
      @media @bw1170 {
        right: 0;
      }
    }
  }
}
</style>
